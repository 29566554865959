import React from "react"
import Typography from "@material-ui/core/Typography"

class ErrorBoundaryState {
  hasError: boolean
  error: any
  errorInfo: any
}

class ErrorBoundary extends React.Component {
  state: ErrorBoundaryState

  constructor(props) {
    super(props)
    this.state = new ErrorBoundaryState()
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Typography variant="body1" gutterBottom>
          Something went wrong.
        </Typography>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
