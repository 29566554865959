import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"

import QRCode from "qrcode.react"

import CopyToClipboardButton from "./copy_to_clipboard"

class ShareState {
  copiedOpen: boolean
}

class ShareDialog extends React.Component<{ open: boolean; onClose() }> {
  state: ShareState

  constructor(props) {
    super(props)
    this.state = new ShareState()
  }

  handleCopiedOpen = () => {
    this.setState({
      copiedOpen: true,
    })

    setTimeout(this.handleCopiedClose, 3000)
  }

  handleCopiedClose = () => {
    this.setState({
      copiedOpen: false,
    })
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle id="share-dialog">Share this tool</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>❶ Scan QR Code</DialogContentText>
          <Box mb={2} display="flex" justifyContent="center">
            <QRCode value={window.location.href} size={128}></QRCode>
          </Box>
          <Box width={260}>
            <Typography variant="body2">
              Use the Camera app on your iPhone, iPad, or iPod touch to scan a
              QR code. <br />
              <a
                href="https://support.apple.com/en-us/HT208843"
                target="_blank"
                rel="noreferrer"
              >
                Learn More...
              </a>
            </Typography>
          </Box>

          <Box mt={2} mb={2}>
            <Divider />
          </Box>

          <DialogContentText>❷ Copy Link</DialogContentText>
          <Box width={1}>
            <Box display="block">
              <TextField
                disabled
                fullWidth
                margin="dense"
                variant="standard"
                defaultValue={window.location.href}
              ></TextField>
            </Box>

            <Box mt={1} display="flex" justifyContent="center">
              <Box mr={2}>
                <Button onClick={this.props.onClose} variant="outlined">
                  Close
                </Button>
              </Box>
              <CopyToClipboardButton value={window.location.href} />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
}

export default ShareDialog
