import React from "react"
import Alert from "@material-ui/lab/Alert"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import InputWidget from "./input"
import OutputWidget from "./output"

import ErrorBoundary from "./error_boundary"

interface PipeProps {
  inputProps?: any
  outputProps?: any

  outputComponent?: any

  transformer?(text: string): string
  exceptionHandler?(error: Error): string

  valueHook?(text: string)
}

class PipeState {
  text: string = ""
  error?: Error
}

class PipeWidget extends React.Component<PipeProps> {
  state: PipeState

  constructor(props: PipeProps) {
    super(props)

    this.state = new PipeState()
  }

  forward = (text: string) => {
    try {
      this.setState({
        text: this.props.transformer ? this.props.transformer(text) : text,
        error: null,
      })
    } catch (e) {
      this.setState({
        text: "",
        error: e,
      })
    }

    try {
      if (this.props.valueHook) {
        this.props.valueHook(text)
      }
    } catch (e) {
      console.error(e)
    }
  }

  errorText = () => {
    return this.props.exceptionHandler
      ? this.props.exceptionHandler(this.state.error)
      : this.state.error?.message
  }

  render() {
    return (
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <Box p={2} boxShadow={4}>
            <InputWidget
              textChanged={text => this.forward(text)}
              {...this.props.inputProps}
            ></InputWidget>
          </Box>
        </Grid>

        {this.state.error && (
          <Grid item>
            <Alert severity="error">{this.errorText()}</Alert>
          </Grid>
        )}

        <Grid item>
          <Typography variant="h6" gutterBottom>
            Result
          </Typography>
        </Grid>

        <Grid item>
          <Box p={2} boxShadow={4}>
            <ErrorBoundary>
              <OutputWidget
                children={this.props.outputComponent}
                text={this.state.text || ""}
                {...this.props.outputProps}
              ></OutputWidget>
            </ErrorBoundary>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default PipeWidget
