export default class Tool {
  public static rootPath = "/t/"

  path: string
  title: string
  description: string
  links: Map<string, string>

  getFullPath() {
    return Tool.rootPath + this.path
  }
}
