import React from "react"
import Hidden from "@material-ui/core/Hidden"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import ToolsHistory from "./helpers/history"
import Layout from "./layout"
import ToolFooter from "./tool_footer"
import SEO from "./seo"

interface ToolLayoutProps {
  data: any
  children: any
}

class ToolLayout extends React.Component<ToolLayoutProps> {
  constructor(props: ToolLayoutProps) {
    super(props)
  }

  componentDidMount() {
    ToolsHistory.getInstance().addTool(this.getTool())
  }

  getTool = () => {
    return this.props.data.allToolsJson.edges[0].node
  }

  render() {
    const tool = this.props.data.allToolsJson.edges[0].node

    return (
      <Layout toolTitle={tool.title} footer={<ToolFooter tool={tool} />}>
        <SEO title={tool.title} description={tool.description} />
        <Hidden xsDown>
          <Box mt={4} fontSize="h5.fontSize" fontWeight="fontWeightBold">
            {tool.title}
          </Box>
        </Hidden>
        <Box mt={1} mb={1}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {tool.description}
          </Typography>
        </Box>
        <Box mb={2}>
          <Divider light />
        </Box>

        <Box mb={4}>{this.props.children}</Box>
      </Layout>
    )
  }
}

export default ToolLayout
