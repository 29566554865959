import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"

import { DiGithub, DiJavascript, DiMozilla } from "react-icons/di"

import Tool from "../helpers/tool"

class ReferencesDialog extends React.Component<{
  open: boolean
  tool: Tool
  onClose()
}> {
  linkListItem = (link: string | null, text: string, icon: any) => {
    if (!link) {
      return null
    }
    let Icon = icon
    return (
      <ListItem dense divider>
        <ListItemAvatar>
          <Avatar variant="square">
            <Icon size={48} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Link
            href={link}
            color="textSecondary"
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </Link>
        </ListItemText>
      </ListItem>
    )
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle id="history-dialog">
          References of {this.props.tool.title}
        </DialogTitle>
        <DialogContent>
          <List component="nav" aria-label="links" dense>
            {this.linkListItem(
              this.props.tool.links["origin"],
              this.props.tool.links["origin"],
              DiJavascript
            )}
            {this.linkListItem(
              this.props.tool.links["mdn"],
              "Mozilla Developer Network",
              DiMozilla
            )}
            {this.linkListItem(
              this.props.tool.links["github"],
              "Github Repo",
              DiGithub
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.onClose}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ReferencesDialog
