import React from "react"
import { styled } from "@material-ui/core/styles"

import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import RestoreIcon from "@material-ui/icons/Restore"
import InfoIcon from "@material-ui/icons/Info"
import ShareIcon from "@material-ui/icons/Share"
import NoSsr from "@material-ui/core/NoSsr"

import HistoryDialog from "./widgets/history_dialog"
import ReferencesDialog from "./widgets/references_dialog"
import ShareDialog from "./widgets/share_dialog"

import Tool from "./helpers/tool"

const StyledBottomNavigation = styled(BottomNavigation)({
  bottom: 0,
})

class FooterState {
  historyDialogOpen: boolean = false
  referencesDialogOpen: boolean = false
  shareDialogOpen: boolean = false
}

class ToolFooter extends React.Component<{ tool: Tool }> {
  state: FooterState

  constructor(props: any) {
    super(props)
    this.state = new FooterState()
  }

  dialogs = () => {
    return (
      <>
        <HistoryDialog
          open={this.state.historyDialogOpen}
          onClose={() => {
            this.setState({ historyDialogOpen: false })
          }}
        />

        <ReferencesDialog
          open={this.state.referencesDialogOpen}
          onClose={() => {
            this.setState({ referencesDialogOpen: false })
          }}
          tool={this.props.tool}
        ></ReferencesDialog>

        <NoSsr>
          <ShareDialog
            open={this.state.shareDialogOpen}
            onClose={() => {
              this.setState({ shareDialogOpen: false })
            }}
          />
        </NoSsr>
      </>
    )
  }

  render() {
    return (
      <>
        {this.dialogs()}

        <StyledBottomNavigation showLabels>
          <BottomNavigationAction
            label="Recents"
            icon={<RestoreIcon />}
            onClick={() => {
              this.setState({ historyDialogOpen: true })
            }}
          />
          <BottomNavigationAction
            label="References"
            icon={<InfoIcon />}
            onClick={() => {
              this.setState({ referencesDialogOpen: true })
            }}
          />
          <BottomNavigationAction
            label="Share"
            icon={<ShareIcon />}
            onClick={() => {
              this.setState({ shareDialogOpen: true })
            }}
          />
        </StyledBottomNavigation>
      </>
    )
  }
}

export default ToolFooter
