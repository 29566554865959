import React from "react"

import TextField from "@material-ui/core/TextField"
import FileCopy from "@material-ui/icons/FileCopy"
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import Box from "@material-ui/core/Box"

import { CopyToClipboard } from "react-copy-to-clipboard"

export interface OutputProps {
  text?: string
  placeHolderText?: string

  children?: any
}

class OutputState {
  copiedOpen: boolean = false
}

class OutputWidget extends React.Component<OutputProps> {
  state: OutputState
  resultTextInput: React.RefObject<HTMLDivElement>

  constructor(props: OutputProps) {
    super(props)
    this.state = new OutputState()
    this.resultTextInput = React.createRef()
  }

  handleTooltipOpen = () => {
    this.setState({
      copiedOpen: true,
    })

    setTimeout(this.handleTooltipClose, 2000)
  }

  handleTooltipClose = () => {
    this.setState({
      copiedOpen: false,
    })
  }

  copyToClipboardButton = () => {
    if (!this.props.text || this.props.text.length === 0) {
      return
    }

    return (
      <CopyToClipboard text={this.props.text} onCopy={this.handleTooltipOpen}>
        <Tooltip
          onClose={this.handleTooltipClose}
          open={this.state.copiedOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="right-end"
          title="Copied to clipboard"
        >
          <Fab aria-label="copy" size="small" variant="extended">
            <FileCopy />
            <Box ml={1}>Copy</Box>
          </Fab>
        </Tooltip>
      </CopyToClipboard>
    )
  }

  render() {
    if (this.props.children) {
      let ChildWidget = this.props.children
      return <ChildWidget value={this.props.text} {...this.props}></ChildWidget>
    }

    return (
      <TextField
        disabled
        fullWidth
        multiline
        rows="8"
        variant="standard"
        value={this.props.text}
        placeholder={this.props.placeHolderText}
        InputProps={{ endAdornment: this.copyToClipboardButton() }}
      ></TextField>
    )
  }
}

export default OutputWidget
