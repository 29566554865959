/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Box from "@material-ui/core/Box"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"

import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"

import "typeface-roboto"
import Header from "./header"

const theme = createMuiTheme({
  palette: {},
})

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#424242",
    },

    background: {
      default: "#757575",
      paper: "#424242",
    },
  },
})

class Layout extends React.Component<{ toolTitle?: string; footer?: any }> {
  render() {
    const query = graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `

    return (
      <StaticQuery
        query={query}
        render={data => (
          <ThemeProvider theme={theme}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CssBaseline />
              <Header
                siteTitle={data.site.siteMetadata.title}
                toolTitle={this.props.toolTitle}
              />
              <main
                style={{
                  flexGrow: 1,
                }}
              >
                <Container fixed>{this.props.children}</Container>
              </main>
              <footer>{this.props.footer}</footer>
            </div>
          </ThemeProvider>
        )}
      ></StaticQuery>
    )
  }
}

export default Layout
