import React from "react"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { CopyToClipboard } from "react-copy-to-clipboard"

class CopyToClipboardButton extends React.Component<{ value: string }> {
  state: { copiedOpen: boolean }

  constructor(props) {
    super(props)
    this.state = { copiedOpen: false }
  }

  handleCopiedOpen = () => {
    this.setState({
      copiedOpen: true,
    })

    setTimeout(this.handleCopiedClose, 3000)
  }

  handleCopiedClose = () => {
    this.setState({
      copiedOpen: false,
    })
  }

  render() {
    return (
      <CopyToClipboard text={this.props.value} onCopy={this.handleCopiedOpen}>
        <Tooltip title="Copy to clipboard">
          <Button
            color="primary"
            variant="contained"
            disabled={!this.props.value || this.props.value.length === 0}
            startIcon={this.state.copiedOpen ? <CheckCircleIcon /> : null}
          >
            {this.state.copiedOpen ? "Copied" : "Copy"}
          </Button>
        </Tooltip>
      </CopyToClipboard>
    )
  }
}

export default CopyToClipboardButton
