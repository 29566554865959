import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import Snackbar from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import Alert from "@material-ui/lab/Alert"

import Avatar from "@material-ui/core/Avatar"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"

import Tool from "../helpers/tool"
import ToolsHistory from "../helpers/history"

interface HistoryProps {
  open: boolean
  onClose()
}

class HistoryState {
  tools: Tool[] = []
}

class HistoryDialog extends React.Component<HistoryProps> {
  state: HistoryState

  constructor(props) {
    super(props)
    this.state = new HistoryState()
  }

  componentDidMount() {
    this.setState({
      tools: ToolsHistory.getInstance().getAll(),
    })
  }

  clearHistory = () => {
    ToolsHistory.getInstance().clear()
    this.props.onClose()
  }

  removeProperty = (obj, propertyName) => {
    let { [propertyName]: _, ...result } = obj
    return result
  }

  render() {
    const { tools } = this.state

    if (tools.length === 0) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.props.open}
          autoHideDuration={3000}
          onClose={this.props.onClose}
          style={{ bottom: 90 }}
        >
          <Alert onClose={this.props.onClose} severity="info" variant="filled">
            Recent tools cleared
          </Alert>
        </Snackbar>
      )
    }

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle id="history-dialog">Recent used tools</DialogTitle>
        <DialogContent>
          <List component="nav" aria-label="history list" dense>
            {tools.reverse().map((tool, index) => (
              <ListItem key={tool.path} button divider dense>
                <ListItemAvatar>
                  <Avatar style={{ width: 24, height: 24 }}>{index + 1}</Avatar>
                </ListItemAvatar>
                <Link
                  color="textSecondary"
                  underline="none"
                  href={Tool.rootPath + tool.path + "/"}
                >
                  <ListItemText primary={tool.title}></ListItemText>
                </Link>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.onClose}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default HistoryDialog
