import React from "react"
import TextField from "@material-ui/core/TextField"
import Fab from "@material-ui/core/Fab"
import Toolbar from "@material-ui/core/Toolbar"

import Switch from "@material-ui/core/Switch"
import PlayArrow from "@material-ui/icons/PlayArrow"
import Tooltip from "@material-ui/core/Tooltip"

import FormControlLabel from "@material-ui/core/FormControlLabel"

export interface InputProps {
  placeHolderText?: string
  runButtonText?: string

  validator?(text: string): string
  textChanged?(text: string)
}

class InputState {
  live?: boolean = true
  trim?: boolean = true
  text: string = ""
  validateText?: string = null
}

class InputWidget extends React.Component<InputProps> {
  state: InputState

  constructor(props: InputProps) {
    super(props)
    this.state = new InputState()
  }

  toolbar = () => {
    return (
      <Toolbar disableGutters variant="dense">
        <Tooltip title="Get the result interactively">
          <FormControlLabel
            label="live mode"
            control={
              <Switch
                color="primary"
                checked={this.state.live}
                onChange={(event, checked) => {
                  this.setState(
                    {
                      live: checked,
                    },
                    () => {
                      if (this.state.live) {
                        this.sendTextChange()
                      } else {
                        this.sendTextClear()
                      }
                    }
                  )
                }}
                inputProps={{ "aria-label": "live checkbox" }}
              />
            }
          ></FormControlLabel>
        </Tooltip>

        <Tooltip title="Trim start/end space of input">
          <FormControlLabel
            label="trim space"
            control={
              <Switch
                color="primary"
                checked={this.state.trim}
                onChange={(event, checked) => {
                  this.setState({ trim: checked }, () => {
                    if (this.state.trim) {
                      this.sendTextChange()
                    }
                  })
                }}
                inputProps={{ "aria-label": "trim checkbox" }}
              />
            }
          ></FormControlLabel>
        </Tooltip>
      </Toolbar>
    )
  }

  runButton = () => {
    if (this.state.live || this.state.validateText != null) {
      return null
    }

    return (
      <Tooltip title="Run" aria-label="run">
        <Fab
          color="primary"
          aria-label="run"
          component="span"
          size="small"
          disabled={this.trimText().length == 0}
          onClick={this.sendTextChange}
        >
          <PlayArrow />
        </Fab>
      </Tooltip>
    )
  }

  inputTextField = () => {
    return (
      <TextField
        autoFocus
        fullWidth
        multiline
        rows="8"
        variant="standard"
        placeholder={this.props.placeHolderText}
        error={this.state.validateText != null}
        helperText={this.state.validateText}
        onChange={event => this.onTextChange(event)}
        InputProps={{ endAdornment: this.runButton() }}
      ></TextField>
    )
  }

  render() {
    return (
      <>
        {this.inputTextField()}
        {this.toolbar()}
      </>
    )
  }

  onTextChange = event => {
    const oldText = this.state.text
    const text = this.state.trim
      ? event.target.value.trim()
      : event.target.value

    const validateText = this.props.validator
      ? this.props.validator(text)
      : null

    this.setState(
      {
        text: text,
        validateText: validateText,
      },
      () => this.notifyTextChange(oldText)
    )
  }

  notifyTextChange = (oldText: string) => {
    if (this.state.live) {
      if (!this.state.validateText) {
        this.props.textChanged(this.state.text)
      } else {
        this.sendTextClear()
      }
    } else {
      // Outdated state
      if (!this.state.trim || oldText !== this.state.text) {
        this.sendTextClear()
      }
    }
  }

  sendTextChange = () => {
    if (this.props.textChanged) {
      this.props.textChanged(this.trimText())
    }
  }

  sendTextClear = () => {
    if (this.props.textChanged) {
      this.props.textChanged("")
    }
  }

  trimText = () => {
    return this.state.trim ? this.state.text.trim() : this.state.text
  }
}

export default InputWidget
