import React from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"

import IconButton from "@material-ui/core/IconButton"
import HomeIcon from "@material-ui/icons/Home"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"

const styles = {
  root: {
    flexGrow: 0,
  },
  menuButton: {
    marginRight: 2,
  },
  title: {
    flexGrow: 1,
    color: "white",
    textDecoration: "none",
  },
  toolTitle: {
    flexGrow: 1,
    color: "lightgrey",
    textDecoration: "none",
  },
}

interface HeaderProps {
  siteTitle: string
  toolTitle?: string
  classes: any
}

class Header extends React.Component<HeaderProps> {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              href="/"
            >
              <HomeIcon />
            </IconButton>

            <Breadcrumbs aria-label="breadcrumb" className={classes.title}>
              <Link to="/" className={classes.title} color="textPrimary">
                <Typography variant="h6">{this.props.siteTitle}</Typography>
              </Link>
              {this.props.toolTitle && (
                <Link
                  to="/"
                  className={classes.toolTitle}
                  color="textSecondary"
                >
                  <Typography variant="body1">
                    {this.props.toolTitle}
                  </Typography>
                </Link>
              )}
            </Breadcrumbs>
            <IconButton color="inherit" href="/about/">
              <ContactSupportIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}
export default withStyles(styles)(Header)
