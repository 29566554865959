import Loadsh from "lodash"

import Tool from "./tool"

export default class ToolsHistory {
  private static instance: ToolsHistory
  private static storageName: string = "tools_history"
  private static maxSize: number = 10

  private constructor() {}

  public static getInstance(): ToolsHistory {
    if (!ToolsHistory.instance) {
      ToolsHistory.instance = new ToolsHistory()
    }

    return ToolsHistory.instance
  }

  public getAll(): Tool[] {
    let str = localStorage.getItem(ToolsHistory.storageName)

    if (!str) {
      return []
    }

    return JSON.parse(str)
  }

  public addTool(tool: Tool) {
    let tools = this.getAll()

    if (Loadsh.find(tools, t => t.path === tool.path)) {
      Loadsh.remove(tools, t => t.path === tool.path)
    }

    tools.push(tool)

    if (tools.length > ToolsHistory.maxSize) {
      tools = tools.slice(1, ToolsHistory.maxSize + 1)
    }

    this.save(tools)
  }

  public clear() {
    this.save([])
  }

  private save(tools: Tool[]) {
    localStorage.setItem(ToolsHistory.storageName, JSON.stringify(tools))
  }
}
